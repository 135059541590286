.group{
    background: #1976d2;
    width: 180px;
    margin: 0 auto 20px;
    display: grid;
    grid-template-columns: 100px 80px;
    border-radius: 15px;
}
input,select{
    background: #1976d2;
    border:0;
    color:#fff;
}
input{
    padding-left: 10px;
}
select{
    padding: 15px;
}