.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #1976d2;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}


.column {
  float: left;
  width: 50%;
  text-align: left;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.fab-container{
  position:fixed;
  bottom:50px;
  right:50px;
  cursor:pointer;
}

.MuiDrawer-paper {
  width: 25% !important;
  }
  
  @media (max-width: 1200px) {
      .MuiDrawer-paper {
   width: 100% !important;
   }
  }

/*-------- Lexical example theme START */
.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  top: 15px;
  left: 15px;
  user-select: none;
  pointer-events: none;
}

.editor-paragraph {
  margin: 0 0 15px 0;
  position: relative;
}

.login {
  height: 100vh;  
  min-height: 100vh;
  text-align: center;

}

.login-logos {
  display: flex;
  height: 100%;
  text-align: center;
  padding:'50px';
  margin:'100px 0px 0px 0px';
  background:'#FFF';
}

.bottom-reff {
  display: inline-block;
  align-self: flex-end;
}
/* Lexical example theme END ----*/